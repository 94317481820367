import React from "react";
import "../HomePage/HomePage.css";
import { Link } from "react-router-dom";
import LogoImg from "../../assets/img/BreathEasy-black-logo.png";
import homeImg from "../../assets/img/homepageImg.jpg";
import hometwoImg from "../../assets/img/doctor-looking-at-clipboard-p-3200.jpg";
import logoicon from "../../assets/img/Logo-Mark.png";
function HomePage() {
  return (
    <>
      <div className="navbar-no-shadow">
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="f-navigation w-nav"
        >
          <div className="f-navigation-container">
            <Link to="#" className="f-navigation-logo-link w-inline-block">
              <img
                src={LogoImg}
                loading="lazy"
                width="124"
                alt=""
                className="f-logo"
              />
            </Link>
            <div className="f-navigation-content">
              {/* <nav role="navigation" className="f-navigation-menu w-nav-menu">
            <Link to="#" className="f-navigation-link w-nav-link">Link One</Link>
            <Link to="#" className="f-navigation-link w-nav-link">Link Two</Link>
            <Link to="#" className="f-navigation-link w-nav-link">Link Three</Link>
            <Link to="#" className="f-navigation-link w-nav-link">Link Four</Link>
          </nav> */}
              <div className="f-navigation-menu-button w-nav-button">
                <div className="w-icon-nav-menu"></div>
              </div>
              <Link to={"/"} className="f-navigation-homepage w-inline-block">
                <div>Home</div>
              </Link>
              <Link
                to={"/login"}
                className="f-navigation-button w-inline-block"
              >
                <div>Get Started</div>
              </Link>
              
            </div>
          </div>
        </div>
      </div>
      <div className="f-section-large">
        <div className="w-layout-grid f-header-grid">
          <div id="w-node-_19b894ea-b57c-8b8d-d65e-7ba4f335beb1-d6ef17d3">
            <div className="">
              <div className="f-heading-detail-small">Breatheasy</div>
            </div>
            <div className="f-margin-bottom-24">
              <h1 className="f-h2-heading">
                Digital health platform for smart respitory medicine
              </h1>
            </div>
            <div className="f-margin-bottom-32">
              <p className="f-paragraph-large">
                Breatheasy revolutionizes respiratory medicine with its
                innovative digital health solution. By integrating sensors into
                the inhaler, Breatheasy creates a connected healthcare
                ecyosystem. These sensors communicate feedback and usage through
                a mobile application, enhancing the patient experience. The
                intelligent analytics provided by the platform enables doctors
                and healthcare providers to deliver superior patient care.
              </p>
            </div>
            <div className="f-header-button-wrapper">
              <Link
                to={"/login"}
                className="f-button-neutral w-inline-block"
                style={{ color: "white" }}
              >
                <div>Get Started</div>
              </Link>
            </div>
          </div>
          <div
            id="w-node-_19b894ea-b57c-8b8d-d65e-7ba4f335bec2-d6ef17d3"
            className="f-header-image-wrapper-tall"
          >
            <img
              src={homeImg}
              // sizes="(max-width: 991px) 80vw, 500px"
              alt=""
              className="f-image-cover"
            />
          </div>
        </div>
        <div className="f-section-large">
          <div className="f-container-regular">
            <div
              id="w-node-_01dac374-ce26-92e2-145a-1e977d6bf38b-d6ef17d3"
              className="f-header-wrapper-left"
            >
              <div className="f-margin-bottom-134">
                <h1 className="f-h2-heading">
                  Personalized disease management
                </h1>
              </div>
              <div className="f-margin-bottom-40">
                <p className="f-paragraph-medium">
                  Respiratory conditions like ashtma and COPD necessitate
                  accurate and consistent medication dosing. However, this
                  consistent dosing is challenging.
                  <br />
                  <br />
                  Breatheasy combines powerful digital health tools and
                  intelligent connected devices that empower patients to
                  autonomously manage their conditions. These innovations not
                  only benefit healthcare providers with advanced treatment
                  avenues but also benefit the patient by equipping them with
                  powerful technology. The sensors instantaneously share data
                  with patients, evaluates their inhalation methods, and ensures
                  optimal medication intake. By assessing factors such as
                  inhalation quality and synchronization between inspiration and
                  device activation, Breatheasy aims to improve patient
                  outcomes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="f-section-large">
          <div className="f-container-regular">
            <div className="f-margin-bottom-131">
              <div className="f-title-wrapper-center">
                <div className="f-margin-bottom-130">
                  <h3 className="f-h3-heading">
                    <strong>
                      A comprehensive and trusted digital health platform
                    </strong>
                    <br />
                  </h3>
                </div>
                <p className="f-paragraph-medium">
                  The Breatheasy Platform is an all-inclusive digital platform,
                  including state-of-the-art medication tracking sensors, an
                  intuitive mobile app/online portal, tailored coaching and
                  support, and robust clinical data for your physician's
                  oversight. This integrated system fosters a more personalized
                  experience for patients.
                </p>
              </div>
            </div>
            <div className="w-layout-grid f-grid-four-columns">
              <div style={{width:"356px"}}>
                <div className="f-feature-icon-wrapper">
                  <img src={logoicon} loading="lazy" alt="" className="imgstylehome"/>
                </div>
                <div className="f-margin-bottom-129">
                  <div className="f-sub-heading-large">Prevention</div>
                </div>
                <p className="f-paragraph-small">
                  Our granular data collected and robust insights help patients
                  be more proactive and prevent illnesses.
                  <br />
                </p>
              </div>
              <div style={{width:"356px"}}>
                <div className="f-feature-icon-wrapper">
                  <img src={logoicon} alt="" className="imgstylehome"/>
                </div>
                <div className="f-margin-bottom-129">
                  <div className="f-sub-heading-large">Support</div>
                </div>
                <p className="f-paragraph-small">
                  Get quick support that empowers patients to receive the care
                  they need to achieve their goals.
                  <br />
                </p>
              </div>
              <div style={{width:"356px"}}>
                <div className="f-feature-icon-wrapper">
                  <img src={logoicon} loading="lazy" alt="" className="imgstylehome"/>
                </div>
                <div className="f-margin-bottom-129">
                  <div className="f-sub-heading-large">Education</div>
                </div>
                <p className="f-paragraph-small">
                  Person-first language around condition, adherence, triggers,
                  inhalation technique, and health trends.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="f-section-large">
          <div className="f-container-regular">
            <div className="w-layout-grid f-grid-two-column">
              <div>
                <div className="f-margin-bottom-133">
                  <h3 className="f-h3-heading">
                    <strong>Creating value for everyone</strong>
                    <br />
                  </h3>
                </div>
                <div className="f-content-divider"></div>
                <div className="f-content-list-wrapper-small">
                  <div className="f-content-list-item-large">
                    <div className="f-content-icon-square">
                      <div className="f-content-icon-square">
                        <div className="f-icon-regular w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11.9993 20.3332C7.39685 20.3332 3.66602 16.6023 3.66602 11.9998C3.66602 7.39734 7.39685 3.6665 11.9993 3.6665C16.6018 3.6665 20.3327 7.39734 20.3327 11.9998C20.3327 16.6023 16.6018 20.3332 11.9993 20.3332ZM10.851 9.01234C10.8009 8.97888 10.7426 8.95964 10.6824 8.95669C10.6221 8.95373 10.5622 8.96716 10.5091 8.99556C10.4559 9.02395 10.4114 9.06623 10.3803 9.11791C10.3493 9.16958 10.3328 9.22871 10.3327 9.289V14.7107C10.3328 14.771 10.3493 14.8301 10.3803 14.8818C10.4114 14.9334 10.4559 14.9757 10.5091 15.0041C10.5622 15.0325 10.6221 15.0459 10.6824 15.043C10.7426 15.04 10.8009 15.0208 10.851 14.9873L14.9169 12.2773C14.9626 12.2469 15.0001 12.2057 15.026 12.1572C15.0519 12.1088 15.0655 12.0548 15.0655 11.9998C15.0655 11.9449 15.0519 11.8908 15.026 11.8424C15.0001 11.794 14.9626 11.7528 14.9169 11.7223L10.8502 9.01234H10.851Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="f-margin-bottom-132">
                        <div className="f-sub-heading-small">For Payers</div>
                      </div>
                      <p className="f-paragraph-regular-2">
                        Reduce healthcare usage and care expenses
                      </p>
                    </div>
                  </div>
                  <div className="f-content-list-item-large">
                    <div className="f-content-icon-square">
                      <div className="f-content-icon-square">
                        <div className="f-icon-regular w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19.5 18.6668C19.5 18.8878 19.4122 19.0998 19.2559 19.256C19.0996 19.4123 18.8877 19.5001 18.6667 19.5001H5.33333C5.11232 19.5001 4.90036 19.4123 4.74408 19.256C4.5878 19.0998 4.5 18.8878 4.5 18.6668V9.76178C4.49995 9.6359 4.52842 9.51165 4.58326 9.39835C4.63811 9.28506 4.71791 9.18565 4.81667 9.10761L11.4833 3.84844C11.6305 3.73219 11.8125 3.66895 12 3.66895C12.1875 3.66895 12.3695 3.73219 12.5167 3.84844L19.1833 9.10678C19.2822 9.18491 19.3621 9.28444 19.4169 9.39789C19.4718 9.51135 19.5002 9.63576 19.5 9.76178V18.6668ZM7.83333 12.0001C7.83333 13.1052 8.27232 14.165 9.05372 14.9464C9.83512 15.7278 10.8949 16.1668 12 16.1668C13.1051 16.1668 14.1649 15.7278 14.9463 14.9464C15.7277 14.165 16.1667 13.1052 16.1667 12.0001H14.5C14.5 12.6632 14.2366 13.299 13.7678 13.7679C13.2989 14.2367 12.663 14.5001 12 14.5001C11.337 14.5001 10.7011 14.2367 10.2322 13.7679C9.76339 13.299 9.5 12.6632 9.5 12.0001H7.83333Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="f-margin-bottom-132">
                        <div className="f-sub-heading-small">
                          For Health Systems
                        </div>
                      </div>
                      <p className="f-paragraph-regular-2">
                        Improve care efficiency and redirect resources elsewhere
                      </p>
                    </div>
                  </div>
                  <div className="f-content-list-item-large">
                    <div className="f-content-icon-square">
                      <div className="f-icon-regular w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewbox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.9993 20.3332C7.39685 20.3332 3.66602 16.6023 3.66602 11.9998C3.66602 7.39734 7.39685 3.6665 11.9993 3.6665C16.6018 3.6665 20.3327 7.39734 20.3327 11.9998C20.3327 16.6023 16.6018 20.3332 11.9993 20.3332ZM10.851 9.01234C10.8009 8.97888 10.7426 8.95964 10.6824 8.95669C10.6221 8.95373 10.5622 8.96716 10.5091 8.99556C10.4559 9.02395 10.4114 9.06623 10.3803 9.11791C10.3493 9.16958 10.3328 9.22871 10.3327 9.289V14.7107C10.3328 14.771 10.3493 14.8301 10.3803 14.8818C10.4114 14.9334 10.4559 14.9757 10.5091 15.0041C10.5622 15.0325 10.6221 15.0459 10.6824 15.043C10.7426 15.04 10.8009 15.0208 10.851 14.9873L14.9169 12.2773C14.9626 12.2469 15.0001 12.2057 15.026 12.1572C15.0519 12.1088 15.0655 12.0548 15.0655 11.9998C15.0655 11.9449 15.0519 11.8908 15.026 11.8424C15.0001 11.794 14.9626 11.7528 14.9169 11.7223L10.8502 9.01234H10.851Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div>
                      <div className="f-margin-bottom-132">
                        <div className="f-sub-heading-small">
                          Pharma &amp; Life Sciences
                        </div>
                      </div>
                      <p className="f-paragraph-regular-2">
                        Precision medication optimization and global connected
                        drug delivery
                      </p>
                    </div>
                  </div>
                </div>
                <div className="f-button-wrapper">
                  <Link
                     to={"/login"}
                    className="f-button-neutral w-inline-block"
                    style={{ color: "white" }}
                  >
                    <div>Get Started</div>
                  </Link>
                </div>
              </div>
              <div
                id="w-node-_40102cb0-cd8a-4d77-820c-80d1a001f08b-d6ef17d3"
                className="f-content-image-tall"
              >
                <img
                  src={hometwoImg}
                  sizes="(max-width: 991px) 81vw, 450px"
                  alt=""
                  className="f-image-cover-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
