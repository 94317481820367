import { useMemo, useState } from "react";
import Progressbar from "react-js-progressbar";
import DataTable from "react-data-table-component";
import { customTableStyles } from "../../utils/DataTableStyle";
import { CgCloseO } from "react-icons/cg";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalConstants } from "../../utils/GlobalConstants";
import Loader from "../../utils/Loader";
import axios from "axios";
import Cookie from "js-cookie";
import {
  errorToast,
  infoToast,
  successToast,
  warnToast,
} from "../../utils/ToasterUtils";
import DatePicker from "react-datepicker";
import ReactDatePicker from "react-multi-date-picker";
import moment from "moment";
import { BiEditAlt } from "react-icons/bi";
import img1 from "../../assets/img/Inhaler_img 1.png";
import { useDispatch, useSelector } from "react-redux";
import { getrefillData } from "../../redux/RefillMedicine";
import { serachMedicine } from "../../redux/GlobalMedicineSlice";
import { useCallback } from "react";
import { getAllDevicesInPopup } from "../../redux/DeviceListSlice";
import { getAllMedicineInPopup } from "../../redux/DashboardSlice";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import { timeZonecountries } from "../../utils/data";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function Mydevice(props) {
  const dispatch = useDispatch();
  const ReFillMedicine = useSelector((state) => state.refillmedicine); //get device,medicine popup
  const medicineSearch = useSelector((state) => state.globalMedicine); // search medicine api

  const currentDevice = props.devicedata;
  const currentMedicine = props.Medicinedata;
  const currentMedicineId =
    props.medicineID === ""
      ? sessionStorage.getItem("medicineID")
      : props.medicineID;
  const currentDeviceID =
    props.DeviceID === "" ? sessionStorage.getItem("deviceID") : props.DeviceID;
  const navigate = useNavigate();
  const DashboardTabData = useLocation();
  const OthersData = DashboardTabData.state;

  // Language Variables
  const [text_is_paired, setText_is_paired] = useState("is paired with");
  const [text_add_device, setText_add_device] = useState("Add Device");
  const [text_add_medicine, setText_add_medicine] = useState("Add Medicine");
  const [text_medicine_schedule, setText_medicine_schedule] =
    useState("Medicine Schedule");
  const [text_exit_view, setText_exit_view] = useState("Exit View");
  const [text_next_dose_time, setText_next_dose_time] =
    useState("Next Dose Time");
  const [text_remaining_dose, setText_remaining_dose] =
    useState("Remaining Doses");
  const [text_estimate_next_refill, setText_estimate_next_refill] = useState(
    "Estimated Next Refill"
  );
  const [text_medicine_level, setText_medicine_level] =
    useState("Medicine Level");
  const [text_enter_dose_manually, setText_enter_dose_manually] = useState(
    "Enter Dose Manually"
  );
  const [text_refill_new_medicine, setText_refill_new_medicine] = useState(
    "Refill or Add New Medicine"
  );
  const [text_add_new_medicine, setText_add_new_medicine] =
    useState("Add New Medicine");
  const [btn_cancel, setBtn_cancel] = useState("Cancel");
  const [btn_save, setBtn_save] = useState("Save");
  const [btn_next, setBtn_next] = useState("Next");
  const [text_upc_code, setText_upc_code] = useState("UPC Code");
  const [text_product_name, setText_product_name] = useState("Product Name");
  const [text_search_upc_product, setText_search_upc_product] = useState(
    "Search UPC Code or Product name.."
  );
  const [text_btn_search, setText_btn_search] = useState("Search");
  const [text_add_manually, setText_add_manually] = useState(
    "want to add manually?"
  );
  const [text_company_name, setText_company_name] = useState("Company Name");
  const [text_ph_company_name, setText_ph_company_name] =
    useState("Enter Company Name");
  const [text_brand_name, setText_brand_name] = useState("Brand Name");
  const [text_ph_brand_name, setText_ph_brand_name] =
    useState("Enter Brand Name");
  const [text_generic_name, setText_generic_name] = useState("Generic Name");
  const [text_ph_generic_name, setText_ph_generic_name] =
    useState("Enter Generic Name");
  const [text_strength, setText_strength] = useState("Strength");
  const [text_ph_strength, setText_ph_strength] = useState("Enter Strength");
  const [text_doses, setText_doses] = useState("Doses");
  const [text_ph_doses, setText_ph_doses] = useState("Enter Doses");
  const [text_tag, setText_tag] = useState("Tag");
  const [text_expiry_date, setText_expiry_date] = useState("Expiry Date");
  const [text_medicine_name, setText_medicine_name] = useState("Medicine Name");
  const [text_num_of_dose, setText_num_of_dose] = useState("Number of Doses");
  const [text_ph_num_of_dose, setText_ph_num_of_dose] = useState(
    "Enter Number of Doses"
  );
  const [text_date_taken, setText_date_taken] = useState("Date Taken");
  const [text_time_taken, setText_time_taken] = useState("Time Taken");
  const [text_select_time, setText_select_time] = useState("Select Time");
  const [text_edit_dose_manually, setText_edit_dose_manually] =
    useState("Edit Dose Manually");
  const [text_date, setText_date] = useState("Date");
  const [text_dose_taken, setText_dose_taken] = useState("Dose Taken");
  const [text_select_dose, setText_select_dose] = useState("Select Dose Taken");
  const [text_dose_override, setText_dose_override] = useState("Dose Override");
  const [text_override_time, setText_override_time] = useState("Override Time");
  const [btn_update, setBtn_update] = useState("Update");
  const [text_day_left_refill, setText_day_left_refill] = useState(
    "Days Left for Refill"
  );
  const [text_srno, setText_srno] = useState("Sr No.");
  const [text_inhaler_position, setText_inhaler_position] =
    useState("Inhaler Position");
  const [text_temperature, setText_temperature] = useState("Temperature");
  const [text_shake_time, setText_shake_time] = useState("Shake Time");
  const [text_dose_left, setText_dose_left] = useState("Dose Left");
  const [text_schedule_time, setText_schedule_time] = useState("Schedule Time");
  const [text_edit, setText_edit] = useState("Edit");
  const [text_succ_medicine_selected, setText_succ_medicine_selected] =
    useState("Medicine Selected!");
  const [text_selected, setText_selected] = useState("Selected");
  const [text_btn_select, setText_btn_select] = useState("Select");
  const [text_warn_fill_any_field, setText_warn_fill_any_field] = useState(
    "Please Fill Any Field First!"
  );
  const [text_warn_medicine_detail, setText_warn_medicine_detail] = useState(
    "Please Add Any Medicine Detail!"
  );
  const [text_warn_search_medicine, setText_warn_search_medicine] = useState(
    "Please Search Any Medicine!"
  );
  const [text_dose, setText_dose] = useState("Dose");
  const [text_select_medicine, setText_select_medicine] =
    useState("Select Medicine");
  const [text_buy_date, setText_buy_date] = useState("Buy Date");
  const [text_refill_medicine, setText_refill_medicine] =
    useState("Refill Medicine");
  const [text_refill, setText_refill] = useState("Refill");
  const [text_warn_medicine_name, setText_warn_medicine_name] = useState(
    "Enter Medicine Name!"
  );
  const [text_history, setText_history] = useState("history");
  const [text_add_medicine_first, setText_add_medicine_first] = useState(
    "Add Your Medicine First"
  );
  const [text_create_schedule, setText_create_schedule] = useState(
    "Create Medicine Schedule"
  );
  const [text_add_medicine_manually, setText_add_medicine_manually] = useState(
    "Add Medicine Manually"
  );
  const [text_Search_Medicine, setText_Search_Medicine] =
    useState("Search Medicine");
  const [text_Refill_Medicine, setText_Refill_Medicine] =
    useState("Refill Medicine");
  const [text_Refill_Medicine_again, setText_Refill_Medicine_again] = useState(
    "Are you sure you want to refill this medicine"
  );
  const [text_device_name, setText_device_name] = useState("Device Name");
  const [text_more, setText_more] = useState("More");
  const [text_No, setText_No] = useState("No");
  const [text_yes, setText_yes] = useState("Yes");
  const [text_number_of_puff_dose, setText_number_of_puff_dose] = useState(
    "Number of Puff/Dose"
  );
  const [Text_select_expiry_date, setText_select_expiry_date] =
    useState("Select Expiry Date");
  const [text_pair_now, setText_pair_now] = useState("Pair Now");
  const [text_pair_later, setText_pair_later] = useState("Pair Later");
  const [text_confirmation_msg, setText_confirmation_msg] = useState(
    "Are you want to Pair This Medicine To Device"
  );
  const [text_add_your_device, setText_add_your_device] = useState(
    "Add Your Device First"
  );
  const [text_select_buy_date, setText_select_buy_date] =
    useState("Select Buy Date");
  const [text_timezone, setText_timezone] = useState("TimeZone");
  const [text_medicine_expiry, setText_medicine_expiry] = useState(
    "Medicine Expiry Alert"
  );
  const [text_alert_befor_expiry, setText_alert_befor_expiry] = useState(
    "Alert Before Expiry"
  );
  const [text_alert_time, setText_alert_time] = useState("Alert Time");
  const [
    text_create_new_medicine_schedule,
    setText_create_new_medicine_schedule,
  ] = useState("Create New Medicine Schedule");
  //language variable end

  const [NewDevicePopup, setNewDevicePopup] = useState(false);
  const [NewMedicinePopup, setNewMedicinePopup] = useState(false);
  const [enterDose, setEnterDose] = useState(false);
  const [edittabledose, setEdittabledose] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [overrideTime, setOverrideTime] = useState("");
  const [overrideDateTime, setOverrideDateTime] = useState(new Date());
  const [editdosetoken, setEditdosetoken] = useState(false);
  const [editdoseoverride, setEditdoseoverride] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const [RefillOldMed, setRefillOldMed] = useState(false);
  const [WantToAddNewMed, setWantToAddNewMed] = useState(false);
  const [AddMedicine2, setAddMedicine2] = useState(false);
  const [WantToAddManually, setWantToAddManually] = useState(false);
  const [WantToAddNewMedicine, setWantToAddNewMedicine] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [pairedMedicine, setPairedMedicine] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [deviceSRNo, setDeviceSRNo] = useState("");
  const [LinkMedicine, setLinkMedicine] = useState("Select");
  const [MedicineSearch, setMedicineSearch] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [BrandName, setBrandName] = useState("");
  const [GenericName, setGenericName] = useState("");
  const [Strength, setStrength] = useState("");
  const [Dose, setDose] = useState("");
  const [Tag, setTag] = useState("");
  const [doseLiveData, setDoseLivedata] = useState([]);
  const [TotalDose, setTotalDose] = useState("");
  const [TotalDays, setTotalDays] = useState("");
  const [remainingDose, setRemainingDose] = useState("");
  const [doseTaken, setDoseTaken] = useState("");
  const [Daysleftforrefill, setDaysleftforrefill] = useState("");
  const [NextDoseTime, setNextDoseTime] = useState("");
  const [EstimatedNextRefill, setestimatenextRefill] = useState("");
  const [medicineLevel, setMedicineLevel] = useState("");
  const [doseTakenAllData, setDoseTakenAllData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const StoreDevice = sessionStorage.getItem("selectedDeviceID");
  const [SelectedDevice, setSelectedDevice] = useState("");
  const [confirmPopup, setConfirmPopup] = useState(false);
  console.log(SelectedDevice);
  const [expiryDate, setExpiryDate] = useState();
  const [buyDate, setBuyDate] = useState(new Date());
  const [editDoseID, setEditDoseId] = useState("");
  const [refillDataId, setRefillDataId] = useState("");
  const [notFoundMedicine, setNotFoundMedicine] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedMedicine, setSelectedMedicine] = useState([]);
  const [medicineName, setMedicineName] = useState("");
  const [doseDataCount, setDoseDataCount] = useState("");
  const [medicineId, setMedicineID] = useState("");
  const [saveDisable, setSaveDisable] = useState(false);
  const [saveDisable1, setSaveDisable1] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [NumDose, setNumDose] = useState("1");
  const [pairMdicinePopup, setPairMedicinePopup] = useState(false);
  const [errSearchMedicine, setErrSearchMedicine] = useState("");
  const [errAddMedicine, setErrAddMedicine] = useState("");
  const [errEnterDoseManually, setErrEnterDoseManually] = useState("");
  const [editDate, setEditDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [selectMedExpiry, setSelectMedExpiry] = useState();
  const [selectMedBuyDate, setSelectMEdBuyDate] = useState();
  const [errEditDoseManually, setErrEditDoseManually] = useState("");
  const [selectedMedicineData, setSelectedMedicineData] = useState("");
  const [refillMedicineId, setRefillMedicineID] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeader, setAlertHeader] = useState("");
  const [selectedMedicineName, setSelectedMedicineName] = useState("");
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertDays, setAlertDays] = useState("5");
  const [RefillBuyDate, setRefillBuyDate] = useState(new Date());
  const [RefillExpiryDate, setRefillExpiryDate] = useState();
  const [AlertTime, setAlertTime] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [AlertDaysget, setAlertDaysget] = useState("");
  const [AlertTimeGet, setAlertTimeGet] = useState("");
  const [alertWarning, setAlertWarning] = useState(false);
  //TimeZone
  var timezones = timeZonecountries[0];
  const FinalData = timezones;
  const [selectedTimeZone, setSelectedTimeZone] = useState(FinalData[0]?.utc);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  console.log(alertDays);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      SelectedDevice !== null ||
      selectedMedicineData !== null ||
      selectedMedicineData !== ""
    ) {
      var selectedMedicineExpiry = selectedMedicineData.medicine_expiry;
      var SelectedMedicineName = selectedMedicineData.Genericname;
      const dt1 = new Date();
      const dt2 = new Date(selectedMedicineExpiry);
      var daysRemaining = Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
      console.log(
        moment(new Date()).format("YYYY-MM-DD") >=
          moment(selectedMedicineExpiry).format("YYYY-MM-DD")
      );
      sessionStorage.setItem("MedicineExpiryDate", selectedMedicineExpiry);
      if (daysRemaining <= 5) {
        setSelectedMedicineName(SelectedMedicineName);
        if (daysRemaining === 0) {
          setAlertHeader("Today");
        } else {
          setAlertHeader(`${daysRemaining} Days`);
        }
        setAlertMessage(moment(selectedMedicineExpiry).format("MMM DD, yyyy"));
      } else {
        console.log("expiry Date");
      }
    }
  }, [SelectedDevice, selectedMedicineData]);
  const getDoseLiveData = () => {
    var CurrentTimeDate = moment(new Date()).format("YYYY-MM-DD hh:mm a");
    var api_url =
      GlobalConstants.Cdomain +
      `/api/breatheasy/v1/medicine/doselivedata?Device_ID=${SelectedDevice}&currentTime=${CurrentTimeDate}`;

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then((res) => {
        if (res.status === 206) {
          setNotFoundMedicine(res.data.message);
        } else {
          setNotFoundMedicine("");
        }
        if (res.status === 200) {
          console.log(res.data.data);
          var data = res.data.data;
          if (data === null) {
            setNotFoundMedicine(res.data.message);
          } else {
            setDoseLivedata(data);
            setTotalDose(data.TotalDose);
            setTotalDays(data.totalDays);
            setRemainingDose(data.RemainingDoses);
            setDoseTaken(data.DoseTaken);
            setDaysleftforrefill(data.DaysLeftforRefill);
            setTotalDays(data.totalDays);
            setNextDoseTime(data.NextDoseTime);
            setestimatenextRefill(data.EstimatedNextRefill);
            setMedicineLevel(data.MedicineLevel);
            setNotFoundMedicine("");
            sessionStorage.setItem("totalDose", data.TotalDose);
            sessionStorage.setItem("totalTakenDose", data.DoseTaken);
          }
        }
        console.log("all medicine res***", res);
      })
      .catch(function (err) {
        // errorToast(err.response.data.message);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      });
    // }
  };

  useEffect(() => {
    if (SelectedDevice !== "") {
      getDoseLiveData();
    }
  }, [SelectedDevice, props.medicineID]);

  const GetPairDevice = () => {
    var userId = sessionStorage.getItem("user_id");
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/device/pairdevice?_userId=" +
      userId;

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(api_url, headerConfig)
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data.data;
          if (data.paireddevice.length !== 0) {
            setIsLoading(false);
            var DevicePair = data.paireddevice;
            console.log(DevicePair);
            setAllDevices(DevicePair);
            setPairedMedicine(data.pairedmedicine);
            if (
              StoreDevice === "" ||
              StoreDevice === null ||
              StoreDevice === undefined
            ) {
              setSelectedDevice(DevicePair[0]._id);
            } else {
              var FilterDevice = DevicePair.filter(
                (e) => e._id === StoreDevice
              );
              console.log(FilterDevice);
              setSelectedDevice(FilterDevice[0]._id);
            }
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch(function (err) {
        console.log(err);
        errorToast(err.response.data.message);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          console.log("Error! Try Again.");
          errorToast(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    if (SelectedDevice !== "") {
      var SelecteDevice = allDevices.filter((e) => e._id === SelectedDevice);
      console.log(SelecteDevice);
      if (SelecteDevice.length > 0) {
        sessionStorage.setItem("deviceID", SelecteDevice[0]._id);
        sessionStorage.setItem("deviceName", SelecteDevice[0].Devicecustomname);
        var DeviceandMedicine = pairedMedicine.filter(
          (val) => val._id === SelecteDevice[0].paired_medicineId
        );
        if (DeviceandMedicine.length > 0) {
          setSelectedMedicineData(DeviceandMedicine[0]);
          setMedicineName(DeviceandMedicine[0]?.Genericname);
          setMedicineID(DeviceandMedicine[0]?._id);
          sessionStorage.setItem("medicineID", DeviceandMedicine[0]?._id);
        } else {
          setMedicineName("");
          setMedicineID("");
        }
      }
    }
    console.log(DeviceandMedicine);
  }, [SelectedDevice, selectedMedicine, pairedMedicine]);
  useEffect(() => {
    GetPairDevice();
  }, [props.DeviceID, props.medicineID]);

  useEffect(() => {
    if (
      NewDevicePopup ||
      AddMedicine2 ||
      NewMedicinePopup ||
      enterDose ||
      edittabledose
    ) {
      document.getElementById("MainDiv").style.pointerEvents = "none";
      document.getElementById("DashboardMainPage").style.filter = "blur(3px)";
      document.getElementById("DashboardMainPage2").style.filter = "blur(3px)";
      document.getElementById("DashboardMainPage3").style.filter = "blur(3px)";
      document.getElementById("DashboardMainPage").style.pointerEvents = "none";
      document.getElementById("DashboardMainPage2").style.pointerEvents =
        "none";
      document.getElementById("DashboardMainPage3").style.pointerEvents =
        "none";
    } else {
      document.getElementById("DashboardMainPage").style.filter = "blur(0px)";
      document.getElementById("DashboardMainPage2").style.filter = "blur(0px)";
      document.getElementById("DashboardMainPage3").style.filter = "blur(0px)";
      document.getElementById("MainDiv").style.pointerEvents = "all";
      document.getElementById("DashboardMainPage").style.pointerEvents = "all";
      document.getElementById("DashboardMainPage2").style.pointerEvents = "all";
      document.getElementById("DashboardMainPage3").style.pointerEvents = "all";
    }
  }, [
    AddMedicine2,
    NewDevicePopup,
    NewMedicinePopup,
    enterDose,
    edittabledose,
  ]);

  const INHALER_DATA = [
    {
      title: <>{text_remaining_dose}</>,
      value: remainingDose,
      outof: TotalDose,
      backgroundColor: "#FFEAD2",
    },
    {
      title: <>{text_dose_taken}</>,
      value: doseTaken,
      outof: TotalDose,
      backgroundColor: "#FEFF86",
    },
    {
      title: <>{text_day_left_refill}</>,
      value: Number(Daysleftforrefill),
      outof: Number(TotalDays),
      backgroundColor: "#FF6969",
    },
  ];

  //datatable data [dose taken all data]
  const getDoseTakenAllData = () => {
    if (SelectedDevice === null || SelectedDevice === "") {
      console.log(currentDeviceID);
    } else {
      var api_url =
        GlobalConstants.Cdomain +
        `/api/breatheasy/v1/medicine/dosetaken/selectall?Device_ID=${SelectedDevice}&limit=25&nextToken=`;

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data);
            var data = res.data.data.dosedata;
            setDoseTakenAllData(data);
            var doseValue = res.data.data;
            var hasMoreDatas = doseValue.hasMoreDatas;
            var nextToken = doseValue.nextToken;
            var dosedataCount = doseValue.dosedataCount;
            setDoseDataCount(dosedataCount);
            sessionStorage.setItem("dosedatacount", dosedataCount);
            sessionStorage.setItem("DoseTaken", nextToken);
            sessionStorage.setItem("hasMoreDatas", hasMoreDatas);
          }
          console.log("all medicine res***", res);
        })
        .catch(function (err) {
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.log("all medicine err******", err);
          }
        });
    }
  };

  useEffect(() => {
    getDoseTakenAllData();
  }, [currentDeviceID, props.DeviceID, SelectedDevice]);

  const filterDoseData = doseTakenAllData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const columns = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_device_name}</>,
      selector: (row) => <>{currentDevice}</>,
    },
    {
      name: <>{text_medicine_name}</>,
      selector: (row) => <>{row.Genericname === "" ? "-" : row.Genericname}</>,
    },
    {
      name: <>{text_date}</>,
      selector: (row) => moment(row.dosetakendate).format("MMM DD, yyyy"),
    },
    {
      name: <>{text_inhaler_position}</>,
      selector: (row) => (
        <img
          src={img1}
          alt=""
          className={
            row.Inhale_Position === "Slightly wrong"
              ? "slightlypostion"
              : row.Inhale_Position === "Incorrect"
              ? "incorrectpostion"
              : "idealpostion"
          }
        />
      ),
      width: "180px",
    },
    {
      name: <>{text_temperature}</>,
      selector: (row) => <>{row.Temperature === "" ? "-" : row.Temperature}</>,
    },
    {
      name: <>{text_shake_time}</>,
      selector: (row) => <>{row.Shake === "" ? "-" : row.Shake}</>,
    },
    {
      name: <>{text_dose_taken}</>,
      selector: (row) => <>{row.isdosetaken === true ? "✅" : "❌"}</>,
    },
    {
      name: <>{text_dose_left}</>,
      selector: (row) => row.doseleft,
    },
    {
      name: <>{text_number_of_puff_dose}</>,
      selector: (row) => (
        <>
          {
            <span
              className={
                row.overridedose === "" || row.overridedose === null
                  ? "text-black"
                  : "text-danger"
              }
            >
              {row.overridedose === "" || row.overridedose === null
                ? row.dosecount
                : row.overridedose}
            </span>
          }
        </>
      ),
    },
    {
      name: <>{text_time_taken}</>,
      selector: (row) => (
        <>
          {row.dosetakentime === "" || row.dosetakentime === null
            ? "-"
            : row.dosetakentime}
        </>
      ),
    },
    {
      name: <>{text_schedule_time}</>,
      selector: (row) => (
        <>
          {row.scheduletime === "" || row.scheduletime === null
            ? "-"
            : row.scheduletime}
        </>
      ),
    },
    {
      name: <>{text_override_time}</>,
      selector: (row) => (
        <>
          {
            <sapn
              className={
                row.overridedosetakentime === "" ||
                row.overridedosetakentime === null
                  ? ""
                  : "text-danger"
              }
            >
              {row.overridedosetakentime === ""
                ? "-"
                : row.overridedosetakentime}
            </sapn>
          }
        </>
      ),
    },
    {
      name: <>{text_edit}</>,
      selector: (row) => (
        <div
          onClick={() => [
            handleOverdoseEdit(row),
            setEdittabledose(!edittabledose),
          ]}
        >
          <BiEditAlt className="editBtn" />
        </div>
      ),
    },
  ];

  const handleOverdoseEdit = (value) => {
    setEditdoseoverride(
      value.overridedose === null ? value.dosecount : value.overridedose
    );
    setEditdosetoken(value.isdosetaken);
    setEditDoseId(value._id);
    setEditDate(value.dosetakendate);
    var Time = value.overridedosetakentime;
    setOverrideTime(Time);
  };
  const handleEditDoseTime = () => {
    var editDoseDeviceID = sessionStorage.getItem("selectedDeviceID");
    var deviceID = sessionStorage.getItem("deviceID");
    var api_url =
      GlobalConstants.Cdomain + "/api/breatheasy/v1/medicine/dosetaken";
    var doseOverRide = {
      _id: editDoseID,
      Device_ID:
        editDoseDeviceID === null ||
        editDoseDeviceID === undefined ||
        editDoseDeviceID === ""
          ? deviceID
          : editDoseDeviceID,
      isdosetaken: editdosetoken,
      overridedose: editdoseoverride,
      overridedosetakentime:
        overrideTime === ""
          ? moment(new Date()).format("h:mm a")
          : overrideTime,
      isWeb: true,
    };

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, doseOverRide, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          successToast(response.data.message);
          getDoseTakenAllData();
          getDoseLiveData();
          setEdittabledose(false);
          setEditdoseoverride("");
        }
      })
      .catch(function (err) {
        // errorToast(err.response.data.message);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          setErrEditDoseManually(<>*{err.response.data.message}!</>);
        }
      });
  };

  const SelectedNewMedicine = (data, index) => {
    setCompanyName(data.Companyname);
    setBrandName(data.Brandname);
    setGenericName(data.Genericname);
    setTag(data.Tag);
    setStrength(data.Strength);
    setDose(data.Dose);
    setRefillOldMed(false);
    setWantToAddNewMed(false);
    setWantToAddManually(false);
    // successToast(<>{text_succ_medicine_selected}</>);
    document.getElementById("SelectBtn" + index).disabled = true;
    document.getElementById("SelectBtn" + index).innerText = text_selected;
    document
      .getElementById("SelectBtn" + index)
      .classList.replace("searchBtn", "SelectedBtn");
    setShowDate(true);
    for (let i = 0; i < searchFilter.length; i++) {
      if (searchFilter[i]._id !== data._id) {
        document.getElementById("SelectBtn" + i).disabled = false;
        document.getElementById("SelectBtn" + i).innerText = text_btn_select;
        document
          .getElementById("SelectBtn" + i)
          .classList.replace("SelectedBtn", "searchBtn");
      }
    }
  };

  const closePopup = () => {
    setNewDevicePopup(false);
    setNewMedicinePopup(false);
    setWantToAddNewMed(false);
    setWantToAddManually(false);
    setAddMedicine2(false);
    setCompanyName("");
    setBrandName("");
    setGenericName("");
    setTag("");
    setDose("");
    setStrength("");
    setDeviceName("");
    setDeviceSRNo("");
    setLinkMedicine("Select");
    setWantToAddNewMedicine(false);
    setRefillOldMed(false);
    setMedicineSearch("");
    setExpiryDate();
    setBuyDate(new Date());
    setEnterDose(false);
    setStartDate(new Date());
    setStartTime(new Date());
    setEdittabledose(false);
    setSelectedTab(0);
    setPairMedicinePopup(false);
    setNewMedicinePopup(false);
    setConfirmPopup(false);
    setErrAddMedicine("");
    setErrSearchMedicine("");
    setErrEnterDoseManually("");
    setErrEditDoseManually("");
    setAlertPopup(false);
    setAlertDays("5");
    setAlertTime(setHours(setMinutes(new Date(), 0), 9));
    setSelectMedExpiry();
    setSelectMEdBuyDate();
    setNumDose("1");
  };

  useEffect(() => {
    if (medicineSearch) {
      dispatch(serachMedicine());
    }
  }, [1000]);

  const searchFilter = medicineSearch.filter((e) => {
    return Object.values(e.Genericname)
      .join("")
      .toLowerCase()
      .includes(MedicineSearch.toLocaleLowerCase());
  });

  const SaveNewMedicine = () => {
    if (
      companyName !== "" ||
      BrandName !== "" ||
      GenericName !== "" ||
      Dose !== "" ||
      Strength !== "" ||
      Tag !== ""
    ) {
      setSaveDisable(true);
      var ExpiryDate = moment(expiryDate).format("YYYY-MM-DD");
      var SelectedExpirydate =
        selectMedExpiry === "" || selectMedExpiry === undefined
          ? ""
          : moment(selectMedExpiry).format("YYYY-MM-DD");
      var buyDateSelect =
        selectMedBuyDate === "" || selectMedBuyDate === undefined
          ? moment(new Date()).format("YYYY-MM-DD")
          : moment(selectMedBuyDate).format("YYYY-MM-DD");
      var api_url =
        GlobalConstants.Cdomain +
        "/api/breatheasy/v1/medicine/addmedicine?type=insert";
      var MedicineData = {
        _userId: sessionStorage.getItem("user_id"),
        Companyname: companyName,
        Brandname: BrandName,
        Genericname: GenericName,
        Strength: Strength,
        Dose: Number(Dose),
        Tag: Tag,
        Addmanually: WantToAddManually,
        AddmedicineLater: false,
        medicine_expiry: selectedTab === 2 ? ExpiryDate : SelectedExpirydate,
        unitofmasurement: "MD",
        buydate:
          selectedTab === 2
            ? moment(buyDate).format("YYYY-MM-DD")
            : buyDateSelect,
      };

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .post(api_url, MedicineData, headerConfig)
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            successToast(response.data.message);
            setSaveDisable(false);
            // closePopup();
            dispatch(getrefillData());
            setSelectedTab(0);
            setCompanyName("");
            setBrandName("");
            setStrength("");
            setTag("");
            setDose("");
            setGenericName("");
            setBuyDate(new Date());
            setExpiryDate();
            setErrSearchMedicine("");
            setErrAddMedicine("");
            setSelectMEdBuyDate();
            setSelectMedExpiry();
          }
        })
        .catch(function (err) {
          setSaveDisable(false);
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            errorToast(err.response.data.message);
          }
        });
    } else {
      if (selectedTab === 1) {
        setErrSearchMedicine(<>{text_warn_search_medicine}</>);
      }
      if (selectedTab === 2) {
        setErrAddMedicine(<>{text_warn_medicine_detail}</>);
      }
    }
  };

  //enter dose manually
  const handleDoseManually = () => {
    setSaveDisable1(true);
    var deviceID =
      sessionStorage.getItem("selectedDeviceID") === "" ||
      sessionStorage.getItem("selectedDeviceID") === null ||
      sessionStorage.getItem("selectedDeviceID") === undefined
        ? sessionStorage.getItem("deviceID")
        : sessionStorage.getItem("selectedDeviceID");
    var api_url =
      GlobalConstants.Cdomain + "/api/breatheasy/v1/medicine/dosedetails";
    var doseData = {
      Device_ID: deviceID,
      dosecount: NumDose,
      dosetakendate: moment(startDate).format("YYYY-MM-DD"),
      dosetakentime: moment(startTime).format("h:mm a"),
      isWeb: true,
    };

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, doseData, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          successToast(response.data.message);
          setSaveDisable1(false);
          setEnterDose(false);
          getDoseTakenAllData();
          getDoseLiveData();
          setEditdoseoverride("1");
          setNumDose("1");
          setStartTime(new Date());
          setStartDate(new Date());
        }
      })
      .catch(function (err) {
        setSaveDisable1(false);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          // errorToast(err.response.data.message);
          setErrEnterDoseManually(<>*{err.response.data.message}!</>);
        }
      });
  };
  const columnsOfMedicine = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_company_name}</>,
      selector: (row) => row.Companyname,
    },
    {
      name: <>{text_product_name}</>,
      selector: (row) => row.Brandname,
    },
    {
      name: <>{text_generic_name}</>,
      selector: (row) => row.Genericname,
    },
    {
      name: <>{text_strength}</>,
      selector: (row) => row.Strength,
    },
    {
      name: <>{text_dose}</>,
      selector: (row) => row.Dose,
    },

    {
      name: <>{text_select_medicine}</>,
      selector: (row, index) => (
        <>
          <button
            className="searchBtn"
            id={"SelectBtn" + index}
            onClick={() => SelectedNewMedicine(row, index)}
          >
            {text_btn_select}
          </button>
        </>
      ),
    },
  ];

  const filterDataOfrefillMedicine = ReFillMedicine.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const handleRefillMedicine = (value, index) => {
    setRefillDataId(value._medicineId);
    var FinalData = [];
    FinalData.push(value);
    setSelectedMedicine(FinalData);
  };

  const columnsOfrefill = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_company_name}</>,
      selector: (row) => row.Companyname,
    },
    {
      name: <>{text_product_name}</>,
      selector: (row) => row.Brandname,
    },
    {
      name: <>{text_generic_name}</>,
      selector: (row) => row.Genericname,
      width: "400px",
    },
    {
      name: <>{text_strength}</>,
      selector: (row) => row.Strength,
    },
    {
      name: <>{text_dose}</>,
      selector: (row) => row.Dose,
    },
    {
      name: <>{text_buy_date}</>,
      selector: (row) => moment(row.buydate).format("MMM DD, yyyy"),
    },
    {
      name: <>{text_expiry_date}</>,
      selector: (row) => (
        <>
          <div className="sharetodataDatepicker">
            <DatePicker
              dropdownMode="select"
              className=" mob-shareto-date"
              onChange={(date) => handleExpiryEdit(date, row._id)}
              dateFormat="dd MMM, yyyy"
              selected={
                row.medicine_expiry === "" || row.medicine_expiry === null
                  ? ""
                  : new Date(row.medicine_expiry)
              }
              minDate={new Date()}
              popperPlacement="auto"
              placeholderText={Text_select_expiry_date}
            />
          </div>
        </>
      ),
    },

    {
      name: <>{text_refill_medicine}</>,
      selector: (row, index) => (
        <>
          <button
            className="searchBtn me-2"
            id={"refillMedicine" + index}
            onClick={() => [
              handleRefillMedicine(row, index),
              setConfirmPopup(!confirmPopup),
            ]}
          >
            {text_refill}
          </button>
        </>
      ),
    },
  ];
  console.log(alertPopup);
  const handleExpiryEdit = (date, ID) => {
    var api_url =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/medicine/medicinerefillhistory/update/medicineexpiry";
    var data = {
      _id: ID,
      medicine_expiry: moment(date).format("YYYY-MM-DD"),
    };

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .patch(api_url, data, headerConfig)
      .then(function (response) {
        if (response.status === 200) {
          var res = response.data;
          successToast(response.data.message);
          dispatch(getrefillData());
        }
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          navigate("/");
        } else {
          console.log("all devices err***", err);
        }
      });
  };

  const _setOverrideTime = (e) => {
    let chdate = e.toISOString();
    let d = moment(chdate).format("h:mm a");
    setOverrideTime(d);
  };

  const handleRefilldata = () => {
    console.log(refillDataId);
    if (refillDataId !== "") {
      setSaveDisable(true);
      var api_url =
        GlobalConstants.Cdomain + "/api/breatheasy/v1/medicine/addrefill";
      var refillData = {
        Device_ID: currentDeviceID,
        _medicineId: refillDataId,
        buydate: moment(RefillBuyDate).format("YYYY-MM-DD"),
        medicine_expiry:
          RefillExpiryDate === "" ||
          RefillExpiryDate === null ||
          RefillExpiryDate === undefined
            ? ""
            : moment(RefillExpiryDate).format("YYYY-MM-DD"),
      };

      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .post(api_url, refillData, headerConfig)
        .then(function (response) {
          console.log(response);
          var SendMedicineId = response.data.data;
          if (response.status === 200) {
            // successToast(response.data.message);
            setSaveDisable(false);
            getDoseTakenAllData();
            getDoseLiveData();
            // setPairMedicinePopup(true);
            setRefillMedicineID(SendMedicineId._id);
            if (
              SendMedicineId.medicine_expiry === "" ||
              SendMedicineId.medicine_expiry === null ||
              SendMedicineId.medicine_expiry === undefined
            ) {
              setAlertPopup(false);
              setPairMedicinePopup(true);
            } else {
              setAlertPopup(true);
            }
          }
        })
        .catch(function (err) {
          setSaveDisable(false);
          // setPairMedicinePopup(false);
          setAlertPopup(false);
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            errorToast(err.response.data.message);
            setPairMedicinePopup(false);
          }
        });
    } else {
      warnToast("Please Select Medicine First");
    }
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_No(
      xpath.select1("/resources/string[@name='text_No']", doc).firstChild.data
    );
    setText_Refill_Medicine_again(
      xpath.select1(
        "/resources/string[@name='text_Refill_Medicine_again']",
        doc
      ).firstChild.data
    );
    setText_is_paired(
      xpath.select1("/resources/string[@name='text_is_paired']", doc).firstChild
        .data
    );
    setText_add_device(
      xpath.select1("/resources/string[@name='text_add_device']", doc)
        .firstChild.data
    );
    setText_add_medicine(
      xpath.select1("/resources/string[@name='text_add_medicine']", doc)
        .firstChild.data
    );
    setText_medicine_schedule(
      xpath.select1("/resources/string[@name='text_medicine_schedule']", doc)
        .firstChild.data
    );
    setText_next_dose_time(
      xpath.select1("/resources/string[@name='text_next_dose_time']", doc)
        .firstChild.data
    );
    setText_remaining_dose(
      xpath.select1("/resources/string[@name='text_remaining_dose']", doc)
        .firstChild.data
    );
    setText_estimate_next_refill(
      xpath.select1("/resources/string[@name='text_estimate_next_refill']", doc)
        .firstChild.data
    );
    setText_medicine_level(
      xpath.select1("/resources/string[@name='text_medicine_level']", doc)
        .firstChild.data
    );
    setText_enter_dose_manually(
      xpath.select1("/resources/string[@name='text_enter_dose_manually']", doc)
        .firstChild.data
    );
    setText_refill_new_medicine(
      xpath.select1("/resources/string[@name='text_refill_new_medicine']", doc)
        .firstChild.data
    );
    setText_add_new_medicine(
      xpath.select1("/resources/string[@name='text_add_new_medicine']", doc)
        .firstChild.data
    );
    setBtn_cancel(
      xpath.select1("/resources/string[@name='btn_cancel']", doc).firstChild
        .data
    );
    setBtn_save(
      xpath.select1("/resources/string[@name='btn_save']", doc).firstChild.data
    );
    setBtn_next(
      xpath.select1("/resources/string[@name='btn_next']", doc).firstChild.data
    );
    setText_upc_code(
      xpath.select1("/resources/string[@name='text_upc_code']", doc).firstChild
        .data
    );
    setText_product_name(
      xpath.select1("/resources/string[@name='text_product_name']", doc)
        .firstChild.data
    );
    setText_search_upc_product(
      xpath.select1("/resources/string[@name='text_search_upc_product']", doc)
        .firstChild.data
    );
    setText_btn_search(
      xpath.select1("/resources/string[@name='text_btn_search']", doc)
        .firstChild.data
    );
    setText_add_manually(
      xpath.select1("/resources/string[@name='text_add_manually']", doc)
        .firstChild.data
    );
    setText_company_name(
      xpath.select1("/resources/string[@name='text_company_name']", doc)
        .firstChild.data
    );
    setText_ph_company_name(
      xpath.select1("/resources/string[@name='text_ph_company_name']", doc)
        .firstChild.data
    );
    setText_brand_name(
      xpath.select1("/resources/string[@name='text_brand_name']", doc)
        .firstChild.data
    );
    setText_ph_brand_name(
      xpath.select1("/resources/string[@name='text_ph_brand_name']", doc)
        .firstChild.data
    );
    setText_generic_name(
      xpath.select1("/resources/string[@name='text_generic_name']", doc)
        .firstChild.data
    );
    setText_ph_generic_name(
      xpath.select1("/resources/string[@name='text_ph_generic_name']", doc)
        .firstChild.data
    );
    setText_strength(
      xpath.select1("/resources/string[@name='text_strength']", doc).firstChild
        .data
    );
    setText_ph_strength(
      xpath.select1("/resources/string[@name='text_ph_strength']", doc)
        .firstChild.data
    );
    setText_doses(
      xpath.select1("/resources/string[@name='text_doses']", doc).firstChild
        .data
    );
    setText_ph_doses(
      xpath.select1("/resources/string[@name='text_ph_doses']", doc).firstChild
        .data
    );
    setText_tag(
      xpath.select1("/resources/string[@name='text_tag']", doc).firstChild.data
    );
    setText_expiry_date(
      xpath.select1("/resources/string[@name='text_expiry_date']", doc)
        .firstChild.data
    );
    setText_buy_date(
      xpath.select1("/resources/string[@name='text_buy_date']", doc).firstChild
        .data
    );
    setText_medicine_name(
      xpath.select1("/resources/string[@name='text_medicine_name']", doc)
        .firstChild.data
    );
    setText_num_of_dose(
      xpath.select1("/resources/string[@name='text_num_of_dose']", doc)
        .firstChild.data
    );
    setText_ph_num_of_dose(
      xpath.select1("/resources/string[@name='text_ph_num_of_dose']", doc)
        .firstChild.data
    );
    setText_date_taken(
      xpath.select1("/resources/string[@name='text_date_taken']", doc)
        .firstChild.data
    );
    setText_time_taken(
      xpath.select1("/resources/string[@name='text_time_taken']", doc)
        .firstChild.data
    );
    setText_select_time(
      xpath.select1("/resources/string[@name='text_select_time']", doc)
        .firstChild.data
    );
    setText_edit_dose_manually(
      xpath.select1("/resources/string[@name='text_edit_dose_manually']", doc)
        .firstChild.data
    );
    setText_date(
      xpath.select1("/resources/string[@name='text_date']", doc).firstChild.data
    );
    setText_dose_taken(
      xpath.select1("/resources/string[@name='text_dose_taken']", doc)
        .firstChild.data
    );
    setText_select_dose(
      xpath.select1("/resources/string[@name='text_select_dose']", doc)
        .firstChild.data
    );
    setText_dose_override(
      xpath.select1("/resources/string[@name='text_dose_override']", doc)
        .firstChild.data
    );
    setText_override_time(
      xpath.select1("/resources/string[@name='text_override_time']", doc)
        .firstChild.data
    );
    setBtn_update(
      xpath.select1("/resources/string[@name='btn_update']", doc).firstChild
        .data
    );
    setText_day_left_refill(
      xpath.select1("/resources/string[@name='text_day_left_refill']", doc)
        .firstChild.data
    );
    setText_srno(
      xpath.select1("/resources/string[@name='text_srno']", doc).firstChild.data
    );
    setText_inhaler_position(
      xpath.select1("/resources/string[@name='text_inhaler_position']", doc)
        .firstChild.data
    );
    setText_temperature(
      xpath.select1("/resources/string[@name='text_temperature']", doc)
        .firstChild.data
    );
    setText_shake_time(
      xpath.select1("/resources/string[@name='text_shake_time']", doc)
        .firstChild.data
    );
    setText_dose_left(
      xpath.select1("/resources/string[@name='text_dose_left']", doc).firstChild
        .data
    );
    setText_schedule_time(
      xpath.select1("/resources/string[@name='text_schedule_time']", doc)
        .firstChild.data
    );
    setText_edit(
      xpath.select1("/resources/string[@name='text_edit']", doc).firstChild.data
    );
    setText_dose(
      xpath.select1("/resources/string[@name='text_dose']", doc).firstChild.data
    );
    setText_succ_medicine_selected(
      xpath.select1(
        "/resources/string[@name='text_succ_medicine_selected']",
        doc
      ).firstChild.data
    );
    setText_selected(
      xpath.select1("/resources/string[@name='text_selected']", doc).firstChild
        .data
    );
    setText_btn_select(
      xpath.select1("/resources/string[@name='text_btn_select']", doc)
        .firstChild.data
    );
    setText_warn_fill_any_field(
      xpath.select1("/resources/string[@name='text_warn_fill_any_field']", doc)
        .firstChild.data
    );
    setText_warn_medicine_detail(
      xpath.select1("/resources/string[@name='text_warn_medicine_detail']", doc)
        .firstChild.data
    );
    setText_warn_search_medicine(
      xpath.select1("/resources/string[@name='text_warn_search_medicine']", doc)
        .firstChild.data
    );
    setText_select_medicine(
      xpath.select1("/resources/string[@name='text_select_medicine']", doc)
        .firstChild.data
    );
    setText_refill_medicine(
      xpath.select1("/resources/string[@name='text_refill_medicine']", doc)
        .firstChild.data
    );
    setText_refill(
      xpath.select1("/resources/string[@name='text_refill']", doc).firstChild
        .data
    );
    setText_warn_medicine_name(
      xpath.select1("/resources/string[@name='text_warn_medicine_name']", doc)
        .firstChild.data
    );
    setText_history(
      xpath.select1("/resources/string[@name='text_history']", doc).firstChild
        .data
    );
    setText_add_medicine_first(
      xpath.select1("/resources/string[@name='text_add_medicine_first']", doc)
        .firstChild.data
    );
    setText_create_schedule(
      xpath.select1("/resources/string[@name='text_create_schedule']", doc)
        .firstChild.data
    );
    setText_device_name(
      xpath.select1("/resources/string[@name='text_device_name']", doc)
        .firstChild.data
    );
    setText_more(
      xpath.select1("/resources/string[@name='text_more']", doc).firstChild.data
    );
    setText_yes(
      xpath.select1("/resources/string[@name='text_yes']", doc).firstChild.data
    );

    setText_add_medicine_manually(
      xpath.select1(
        "/resources/string[@name='text_add_medicine_manually']",
        doc
      ).firstChild.data
    );
    setText_Search_Medicine(
      xpath.select1("/resources/string[@name='text_Search_Medicine']", doc)
        .firstChild.data
    );
    setText_Refill_Medicine(
      xpath.select1("/resources/string[@name='text_Refill_Medicine']", doc)
        .firstChild.data
    );
    setText_number_of_puff_dose(
      xpath.select1("/resources/string[@name='text_number_of_puff_dose']", doc)
        .firstChild.data
    );
    setText_select_expiry_date(
      xpath.select1("/resources/string[@name='Text_select_expiry_date']", doc)
        .firstChild.data
    );
    setText_pair_now(
      xpath.select1("/resources/string[@name='text_pair_now']", doc).firstChild
        .data
    );
    setText_pair_later(
      xpath.select1("/resources/string[@name='text_pair_later']", doc)
        .firstChild.data
    );
    setText_confirmation_msg(
      xpath.select1("/resources/string[@name='text_confirmation_msg']", doc)
        .firstChild.data
    );
    setText_add_your_device(
      xpath.select1("/resources/string[@name='text_add_your_device']", doc)
        .firstChild.data
    );
    setText_select_buy_date(
      xpath.select1("/resources/string[@name='text_select_buy_date']", doc)
        .firstChild.data
    );
    setText_timezone(
      xpath.select1("/resources/string[@name='text_timezone']", doc).firstChild
        .data
    );
    setText_medicine_expiry(
      xpath.select1("/resources/string[@name='text_medicine_expiry']", doc)
        .firstChild.data
    );
    setText_alert_befor_expiry(
      xpath.select1("/resources/string[@name='text_alert_befor_expiry']", doc)
        .firstChild.data
    );
    setText_alert_time(
      xpath.select1("/resources/string[@name='text_alert_time']", doc)
        .firstChild.data
    );
    setText_create_new_medicine_schedule(
      xpath.select1(
        "/resources/string[@name='text_create_new_medicine_schedule']",
        doc
      ).firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  let pairPopupData = useCallback(() => [
    props.RefillPopup(true),
    props.RefillmedicineId(refillMedicineId),
    closePopup(),
    dispatch(getAllDevicesInPopup()),
    dispatch(getAllMedicineInPopup()),
  ]);

  const handleExpiryAlert = (rowData) => {
    console.log(rowData);
    var FinalData = [];
    FinalData.push(rowData);
    setSelectedMedicine(FinalData);
  };

  const handleAlertSetting = () => {
    // var medicineID = sessionStorage.getItem("medicineID");
    var getHours = selectedTimeZone.slice(1, 3);
    var getMinitus = selectedTimeZone.slice(4, 6);
    var hourtoMinitus = Math.floor(getHours * 60);
    var FinalTimezoneOffset = -(Number(hourtoMinitus) + Number(getMinitus));
    var api_url =
      GlobalConstants.Cdomain + "/api/breatheasy/v1/alert/insertalert";
    var alertData = {
      _medicineId: refillMedicineId,
      alertdatetime: "",
      alertTimeInDays: alertDays,
      alerttime: moment(new Date(AlertTime).getTime()).format("hh:mm a"),
      timezoneoffset: FinalTimezoneOffset,
    };

    const headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .post(api_url, alertData, headerConfig)
      .then(function (response) {
        console.log(response);
        setAlertPopup(false);
        setPairMedicinePopup(true);
      })
      .catch(function (err) {
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          errorToast(err.response.data.message);
          console.log(err);
          setPairMedicinePopup(false);
        }
      });
  };

  const getAlertData = () => {
    var medicineID = sessionStorage.getItem("medicineID");
    var userId = sessionStorage.getItem("user_id");
    if (medicineID === null || medicineID === undefined || medicineID === "") {
      console.log("no Medicine ID");
    } else {
      var api_url =
        GlobalConstants.Cdomain +
        `/api/breatheasy/v1/alert/selectallalert?_medicineId=${medicineID}&_userId=${userId}`;
      const headerConfig = {
        headers: {
          accept: "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      };

      axios
        .get(api_url, headerConfig)
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data.data;
            var MEdExpiryDate = sessionStorage.getItem("medicineID");
            if (data.length !== 0) {
              setAlertDaysget(data[0].alertTimeInDays);
              var FinalData = data.filter(
                (val) => val._medicineId === MEdExpiryDate
              );
              setAlertTimeGet(FinalData[0].alerttime);
              console.log(FinalData[0].alerttime);
            } else {
              console.log(response.data.message);
            }
          }
        })
        .catch(function (err) {
          errorToast(err.response.data.message);
          if (err.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("token");
            Cookie.remove("user_email");
            Cookie.remove("firstname");
            Cookie.remove("lastname");
            Cookie.remove("user_id");
            Cookie.remove("token_start_time");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.log("Error! Try Again.");
            errorToast(err.response.data.message);
          }
        });
    }
  };
  useEffect(() => {
    getAlertData();
  }, []);
  useEffect(() => {
    var newAlertDate = new Date();
    var newTime = moment(newAlertDate.getTime()).format("hh:mm a");
    var MedicineExpiryDate = sessionStorage.getItem("MedicineExpiryDate");
    var AlertDate = new Date(`${MedicineExpiryDate}`);
    var FinalDate = AlertDate.setDate(AlertDate.getDate() - AlertDaysget);
    console.log("Selected Time", newTime === AlertTimeGet);
    if (
      moment(FinalDate).format("MMM DD,yyyy") >=
      moment(MedicineExpiryDate).format("MMM DD,yyyy")
    ) {
      setAlertWarning(true);
      if (newTime === AlertTimeGet) {
        setOpen(true);
      }
    } else {
      // setOpen(false);
      // setAlertWarning(false);
    }
  }, [AlertTimeGet]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div id="main-alert-box">
          <DialogTitle className="text-center text-alert-title">
            {selectedMedicineName} Expire <b>{alertHeader} </b>
          </DialogTitle>

          <DialogContent>
            <hr />
            <DialogContentText
              id="alert-dialog-description"
              className="text-alert-title text-center"
            >
              Your Current Paired Medicine <b>{selectedMedicineName}</b> is
              Expire <br />
              <b>{alertMessage}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="button-div-sty">
            <button onClick={handleClose} className="alertButton">
              Ok
            </button>
          </DialogActions>
        </div>
      </Dialog>
      {/* {alertWarning?(<><div className="alert alert-warning alert-expiry">
    <strong>Warning!</strong>  Your Current Paired Medicine <b>{selectedMedicineName}</b> is
              Expire {" "}
              <b>{alertMessage}</b>
  </div></>):("")
      
} */}
      <div
        className={
          NewMedicinePopup ||
          AddMedicine2 ||
          NewDevicePopup ||
          enterDose ||
          edittabledose ||
          pairMdicinePopup ||
          alertPopup
            ? "blurBg"
            : ""
        }
        id="MainDiv"
      >
        {IsLoading === true ? (
          <>
            <div className="dashboard-table-loader">
              <Loader />
            </div>
          </>
        ) : (
          <>
            <div
              className={
                OthersData === null
                  ? "mt-3 SelectDeviceHeading"
                  : "mt-3 ViewDeviceHeading "
              }
            >
              {SelectedDevice === "" ? (
                <>
                  <div className="mb-2"></div>
                  <div className="d-flex gap-3 flex-wrap">
                    <button
                      className="roundedBtn"
                      onClick={() => [
                        navigate("/register/device"),
                        sessionStorage.removeItem("deviceID"),
                        sessionStorage.removeItem("medicineID"),
                        sessionStorage.removeItem("MedicineID"),
                      ]}
                    >
                      + {text_add_device}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-2">
                    <select
                      className="Device_select"
                      onChange={(e) => [
                        setSelectedDevice(e.target.value),
                        sessionStorage.setItem(
                          "selectedDeviceID",
                          e.target.value
                        ),
                      ]}
                      value={SelectedDevice}
                    >
                      {allDevices?.map((v, i) => (
                        <>
                          {" "}
                          <option value={v._id} key={v._id}>
                            {pairedMedicine
                              .filter((e) => e._id === v.paired_medicineId)
                              .map((val) => val.Genericname).length === 0
                              ? v?.Devicecustomname
                              : v?.Devicecustomname +
                                " is Paired with " +
                                pairedMedicine
                                  .filter((e) => e._id === v.paired_medicineId)
                                  .map((val) => val.Genericname)}
                          </option>
                        </>
                      ))}
                    </select>
                    <br />
                    <h5 className="mt-3">
                      <b>
                        {sessionStorage.getItem("deviceName") === null ||
                        sessionStorage.getItem("deviceName") === undefined
                          ? currentDevice
                          : sessionStorage.getItem("deviceName")}
                      </b>{" "}
                      {medicineName === "" ? (
                        ""
                      ) : (
                        <>
                          {text_is_paired} <b> {medicineName}</b>
                        </>
                      )}{" "}
                    </h5>
                  </div>
                  <div className="d-flex gap-3 flex-wrap">
                    <button
                      className="roundedBtn"
                      onClick={() => [
                        navigate("/register/device"),
                        sessionStorage.removeItem("deviceID"),
                        sessionStorage.removeItem("medicineID"),
                        sessionStorage.removeItem("MedicineID"),
                      ]}
                    >
                      + {text_add_device}
                    </button>
                    <button
                      className="roundedBtn"
                      onClick={() => [
                        setNewMedicinePopup(!NewMedicinePopup),
                        dispatch(getrefillData()),
                      ]}
                    >
                      + {text_add_medicine}
                    </button>
                    <button
                      className="roundedBtn"
                      onClick={() =>
                        navigate("/schedule-medicine", {
                          state: { data: medicineId },
                        })
                      }
                    >
                      {text_create_new_medicine_schedule}
                    </button>
                  </div>
                </>
              )}
            </div>
            {SelectedDevice === "" ? (
              <h3 className="searchText text-center mt-5">
                {text_add_your_device}!
              </h3>
            ) : (
              <>
                {medicineName === "" ? (
                  <h3 className="searchText text-center mt-5">
                    {notFoundMedicine}
                  </h3>
                ) : (
                  <>
                    {" "}
                    <div className="mt-4 DashboardBox">
                      {INHALER_DATA.map((InhalerData, index) => (
                        <div
                          className="DeviceDataCardBox"
                          key={index}
                          id="progressbarContainer"
                        >
                          <h5 className="mb-3 mt-4 mb-5">
                            {InhalerData?.title}
                          </h5>
                          {console.log(Number(InhalerData?.outof) / 1.333)}
                          <Progressbar
                            input={
                              InhalerData?.title?.props?.children ===
                                "Remaining Doses" ||
                              InhalerData?.title?.props?.children ===
                                "Dose Taken" ||
                              InhalerData?.title?.props?.children ===
                                "Days Left for Refill"
                                ? (InhalerData?.value / InhalerData?.outof) *
                                  100
                                : InhalerData?.value
                            }
                            pathWidth={10}
                            pathColor={
                              InhalerData?.title?.props?.children ===
                                "Remaining Doses" ||
                              InhalerData?.title?.props?.children ===
                                "Dose Taken" ||
                              InhalerData?.title?.props?.children ===
                                "Days Left for Refill"
                                ? (InhalerData?.value / InhalerData?.outof) *
                                    100 >
                                  70
                                  ? "green"
                                  : (InhalerData?.value / InhalerData?.outof) *
                                      100 >
                                    30
                                  ? "orange"
                                  : "red"
                                : InhalerData?.value <= 5
                                ? "red"
                                : InhalerData?.value <= 10
                                ? "orange"
                                : "green"
                            }
                            trailColor="#FFF"
                            backgroundColor={
                              InhalerData?.title?.props?.children ===
                                "Remaining Doses" ||
                              InhalerData?.title?.props?.children ===
                                "Dose Taken" ||
                              InhalerData?.title?.props?.children ===
                                "Days Left for Refill"
                                ? (InhalerData?.value / InhalerData?.outof) *
                                    100 >
                                  70
                                  ? "#90EE90"
                                  : (InhalerData?.value / InhalerData?.outof) *
                                      100 >
                                    30
                                  ? "#FED8B1"
                                  : "#FFCCCB"
                                : InhalerData?.value <= 5
                                ? "#FFCCCB"
                                : InhalerData?.value <= 10
                                ? "#FED8B1"
                                : "#90EE90"
                            }
                            textStyle={{ fill: "black", fontSize: "32px" }}
                            size={130}
                            customText={
                              InhalerData?.outof !== 0
                                ? `${InhalerData?.value}/${InhalerData?.outof}`
                                : `${InhalerData?.value}`
                            }
                          />
                        </div>
                      ))}
                      <div className="DeviceDataBox p-4">
                        {NextDoseTime === null ? (
                          ""
                        ) : (
                          <h5>
                            <b>{text_next_dose_time} :</b>{" "}
                            {moment(NextDoseTime).format("h:mm a")}
                          </h5>
                        )}

                        <h5>
                          <b>{text_remaining_dose} :</b> {remainingDose}
                        </h5>
                        <h5>
                          <b>{text_estimate_next_refill} :</b>{" "}
                          {moment(EstimatedNextRefill).format("MMMM DD, yyyy")}
                        </h5>
                        <h5>
                          <b>{text_medicine_level} :</b> {medicineLevel}{" "}
                          {medicineLevel === "Low" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1}
                              stroke="red"
                              className="icons"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                              />
                            </svg>
                          )}
                        </h5>
                      </div>
                    </div>
                  </>
                )}{" "}
              </>
            )}
            <div className="mt-5">
              {currentMedicineId === "" || currentMedicineId === null ? (
                ""
              ) : (
                <>
                  {medicineName === "" || medicineId === "" ? (
                    <div className="d-flex flex-wrap justify-content-center">
                      <h5 className="text-center ">
                        <b>{sessionStorage.getItem("deviceName")}</b>{" "}
                        {text_history}
                      </h5>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex flex-wrap justify-content-center">
                        <h5 className="text-center ">
                          <b>{sessionStorage.getItem("deviceName")}</b>{" "}
                          {text_history}
                        </h5>
                      </div>
                      <div className="d-flex flex-wrap justify-content-end">
                        <button
                          className="viewschedulebtn"
                          onClick={() => setEnterDose(!enterDose)}
                        >
                          {text_enter_dose_manually}
                        </button>
                      </div>
                    </>
                  )}

                  <div className="mt-2">
                    {IsLoading === true ? (
                      <>
                        <div className="dashboard-table-loader">
                          <Loader />
                        </div>
                      </>
                    ) : (
                      <>
                        <DataTable
                          columns={columns}
                          // pagination
                          data={filterDoseData}
                          fixedHeader
                          selectableRowsHighlight
                          highlightOnHover
                          customStyles={customTableStyles}
                        />
                        <Divider />
                        {doseDataCount > 11 && (
                          <div className="next-btn-sty mt-2">
                            <button
                              onClick={() =>
                                navigate("/mydeviceMedicine/history", {
                                  state: { data: currentDevice },
                                })
                              }
                              className={"savebtn"}
                            >
                              {text_more}
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      {enterDose ? (
        <>
          <div className="Popup addmedicinePopup2">
            <div className="text-end">
              <CgCloseO size={25} onClick={closePopup} />
              <h4 className="text-center">{text_enter_dose_manually}</h4>
              <Divider />
            </div>
            <div className="d-flex justify-content-center gap-2 mt-3">
              <h5>{text_medicine_name} : </h5>
              <h5>{medicineName}</h5>
            </div>
            <div className="row p-2 mt-2">
              <div className="col-md-4">
                <h5 className="mt-1">{text_number_of_puff_dose}</h5>

                <select
                  className="NumberOfDose mt-2"
                  onChange={(e) => setNumDose(e.target.value)}
                  value={NumDose}
                >
                  <option value="1" selected>
                    1
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div className="col-md-4">
                <h5 className="mt-1">{text_date_taken}</h5>
                <DatePicker
                  className="mt-1 frequencyInput"
                  selected={startDate}
                  dateFormat="dd MMM, yyyy"
                  onChange={(date) => setStartDate(date)}
                  maxDate={startDate}
                />
              </div>
              <div className="col-md-4">
                <h5 className="mt-1">{text_time_taken}</h5>
                <DatePicker
                  className="frequencyInput"
                  showTimeSelectOnly
                  showTimeSelect
                  onChange={(date) => setStartTime(date)}
                  dateFormat="h:mm a"
                  selected={startTime}
                  value={startTime}
                  placeholderText={text_select_time}
                />
              </div>
            </div>
            <p className="ErrMessage text-center">{errEnterDoseManually}</p>
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                className="cancelbtn"
                onClick={() => [
                  setEnterDose(false),
                  setEnterDose(false),
                  setStartDate(new Date()),
                  setStartTime(new Date()),
                  setErrEnterDoseManually(""),
                  setNumDose("1"),
                ]}
              >
                {btn_cancel}
              </button>
              <button
                className="savebtn"
                onClick={handleDoseManually}
                disabled={saveDisable1}
              >
                {btn_save}
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {edittabledose ? (
        <>
          <div className="Popup addmedicinePopup2">
            <div className="text-end">
              <CgCloseO size={25} onClick={closePopup} />
              <h4 className="text-center">{text_edit_dose_manually}</h4>
              <Divider />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <h5>{text_date} : &nbsp;</h5>
              <h5>{moment(editDate).format("MMM DD, yyyy")}</h5>
            </div>
            <div className="row p-2 mt-2">
              <div className="col-md-4">
                <h5 className="mt-1">{text_dose_taken}</h5>
                <select
                  name=""
                  id=""
                  value={editdosetoken}
                  onChange={(e) => setEditdosetoken(e.target.value)}
                >
                  <option value="">{text_select_dose}</option>
                  <option value={true}>✅</option>
                  <option value={false}>❌</option>
                </select>
              </div>
              <div className="col-md-4">
                <h5 className="mt-1">{text_number_of_puff_dose}</h5>

                <select
                  className="NumberOfDose mt-2"
                  onChange={(e) => setEditdoseoverride(e.target.value)}
                  value={editdoseoverride}
                >
                  <option value="1" selected>
                    1
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div className="col-md-4">
                <h5 className="mt-1">{text_override_time}</h5>
                <DatePicker
                  className="frequencyInput"
                  showTimeSelectOnly
                  showTimeSelect
                  onChange={(date) => [
                    _setOverrideTime(date),
                    setOverrideDateTime(date),
                  ]}
                  dateFormat="hh:mm a"
                  selected={overrideDateTime}
                />
                {console.log(overrideTime)}
              </div>
            </div>
            <p className="ErrMessage text-center">{errEditDoseManually}</p>
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                className="cancelbtn"
                onClick={() => [
                  setEdittabledose(false),
                  setErrEditDoseManually(""),
                ]}
              >
                {btn_cancel}
              </button>
              <button className="savebtn" onClick={handleEditDoseTime}>
                {btn_update}
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {NewMedicinePopup && (
        <div
          className={
            selectedTab === 2
              ? "mt-4 Popup addmedicinePopup2"
              : confirmPopup || alertPopup
              ? "blurBg Popup addmedicinePopup"
              : "Popup addmedicinePopup"
          }
        >
          <div className="text-end">
            <CgCloseO size={25} onClick={closePopup} />
          </div>
          <h3 className="text-center">{text_add_medicine}</h3>
          <Divider />
          <AppBar
            position="static"
            className="MainAppBar mt-2"
            id="DashboardMainPage2"
          >
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
            >
              <Tab
                label={text_Refill_Medicine}
                className="AppBarText"
                onClick={() => setShowDate(false)}
              />
              <Tab
                label={text_Search_Medicine}
                iconPosition="end"
                className="AppBarText"
                onClick={() => setMedicineSearch("")}
              />
              <Tab
                label={text_add_medicine_manually}
                iconPosition="end"
                className="AppBarText"
                onClick={() => setShowDate(false)}
              />
            </Tabs>
          </AppBar>
          <br />
          {selectedTab === 0 && (
            <>
              <DataTable
                columns={columnsOfrefill}
                data={filterDataOfrefillMedicine}
                fixedHeader
                selectableRowsHighlight
                highlightOnHover
                pagination
                pageLength={5}
                customStyles={customTableStyles}
                className="table-heigth-refill"
                rowsPerPageOptions={[5, 10, 15]}
              />
              <div className="d-flex gap-3 justify-content-center mt-2">
                <button className="cancelbtn" onClick={closePopup}>
                  {btn_cancel}
                </button>
              </div>
            </>
          )}
          {selectedTab === 1 && (
            <>
              {" "}
              <div className="">
                <h5 className="text-center mt-3">
                  {text_upc_code}/{text_product_name}
                </h5>
                <div className="addMedicineSearchBox ">
                  <div>
                    <input
                      className="sharedtoinputdatepicker"
                      placeholder={text_search_upc_product}
                      onChange={(e) => [
                        setMedicineSearch(e.target.value),
                        setErrSearchMedicine(""),
                      ]}
                    />
                    <p className="ErrMessage">{errSearchMedicine}</p>
                  </div>
                  <div>
                    <button
                      className="searchBtn"
                      onClick={() =>
                        MedicineSearch === ""
                          ? setErrSearchMedicine(text_warn_medicine_detail)
                          : dispatch(serachMedicine())
                      }
                    >
                      <AiOutlineSearch />
                      {text_btn_search}
                    </button>
                  </div>
                </div>

                <div className="mt-3 mb-4">
                  {MedicineSearch === "" ? (
                    ""
                  ) : (
                    <>
                      {showDate && (
                        <>
                          <div className="d-flex flex-wrap justify-content-center gap-3 mt-3 mb-2">
                            <div className="addMedicineInput sharetodataDatepicker">
                              <h6>{text_buy_date}</h6>
                              <DatePicker
                                dateFormat="dd MMM, yyyy"
                                className="sharedtoinputdatepicker size-reg-page-date"
                                onChange={(date) => setSelectMEdBuyDate(date)}
                                placeholderText={text_select_buy_date}
                                selected={selectMedBuyDate}
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                            </div>
                            <div className="addMedicineInput sharetodataDatepicker">
                              <h6>{text_expiry_date}</h6>
                              <DatePicker
                                dateFormat="dd MMM, yyyy"
                                className=" sharedtoinputdatepicker size-reg-page-date"
                                onChange={(date) => setSelectMedExpiry(date)}
                                selected={selectMedExpiry}
                                placeholderText={Text_select_expiry_date}
                                dropdownMode="select"
                                minDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <DataTable
                        columns={columnsOfMedicine}
                        data={searchFilter}
                        fixedHeader
                        selectableRowsHighlight
                        highlightOnHover
                        customStyles={customTableStyles}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="text-center">
                <div className="sa-ca-btn-div">
                  <button className="cancelbtn" onClick={closePopup}>
                    {btn_cancel}
                  </button>
                  <button
                    className="savebtn"
                    onClick={SaveNewMedicine}
                    disabled={saveDisable}
                  >
                    {btn_save}
                  </button>
                </div>
              </div>
            </>
          )}
          {selectedTab === 2 && (
            <>
              <div className="mt-4 d-flex flex-wrap gap-3 justify-content-center manually_medicine mob-manually-med">
                <div className="mob-div-manually">
                  <h5>{text_company_name}</h5>
                  <input
                    placeholder={text_ph_company_name}
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                  />
                </div>
                <div className="mob-div-manually">
                  <h5>{text_brand_name}</h5>
                  <input
                    placeholder={text_ph_brand_name}
                    onChange={(e) => setBrandName(e.target.value)}
                    value={BrandName}
                  />
                </div>
                <div className="mob-div-manually">
                  <h5>{text_generic_name}</h5>
                  <input
                    placeholder={text_ph_generic_name}
                    onChange={(e) => setGenericName(e.target.value)}
                    value={GenericName}
                  />
                </div>
                <div className="mob-div-manually">
                  <h5>{text_strength}</h5>
                  <input
                    placeholder={text_ph_strength}
                    onChange={(e) => setStrength(e.target.value)}
                    value={Strength}
                  />
                </div>
                <div className="mob-div-manually">
                  <h5>{text_doses}</h5>
                  <input
                    placeholder={text_ph_doses}
                    onChange={(e) => setDose(e.target.value)}
                    value={Dose}
                  />
                </div>
                <div className="mob-div-manually">
                  <h5>{text_tag}</h5>
                  <input
                    placeholder="e.g B.P"
                    onChange={(e) => setTag(e.target.value)}
                    value={Tag}
                  />
                </div>
                <div className="sharetodataDatepicker mob-div-manually">
                  <h5>{text_buy_date}</h5>
                  <DatePicker
                    dateFormat="dd MMM, yyyy"
                    onChange={(date) => setBuyDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    maxDate={buyDate}
                    selected={buyDate}
                    dropdownMode="select"
                    className="sharedtoinputdatepicker size-datepicker"
                  />
                </div>
                <div className="sharetodataDatepicker mob-div-manually">
                  <h5>{text_expiry_date}</h5>
                  <DatePicker
                    dateFormat="dd MMM, yyyy"
                    onChange={(date) => setExpiryDate(date)}
                    placeholderText={Text_select_expiry_date}
                    selected={expiryDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={new Date()}
                    className="sharedtoinputdatepicker size-datepicker"
                  />
                </div>
              </div>
              <p className="ErrMessage text-center mt-1">{errAddMedicine}</p>

              <div className="d-flex gap-3 justify-content-center mt-3">
                <button className="cancelbtn" onClick={closePopup}>
                  {btn_cancel}
                </button>
                <button
                  className="savebtn"
                  onClick={SaveNewMedicine}
                  disabled={saveDisable}
                >
                  {btn_save}
                </button>
              </div>
            </>
          )}
        </div>
      )}
      {confirmPopup ? (
        <>
          <div
            className={
              pairMdicinePopup || alertPopup
                ? "Popup addmedicinePopup confirmPopup blurBg"
                : "Popup addmedicinePopup confirmPopup "
            }
          >
            <div className="text-end">
              <CgCloseO
                size={25}
                onClick={() => [
                  setConfirmPopup(false),
                  setRefillDataId(""),
                  setRefillExpiryDate(),
                  setRefillBuyDate(new Date()),
                ]}
              />
            </div>
            <h3 className="text-center">
              <>{text_Refill_Medicine_again}</>
            </h3>
            <Divider />
            {selectedMedicine.map((val) => {
              return (
                <>
                  <div className="medicine-box">
                    <div className="flex-container">
                      <div className="flex-item-left">
                        <h6>{text_company_name}:</h6>
                      </div>
                      <div className="flex-item-right">{val.Companyname}</div>
                    </div>
                    <div className="flex-container">
                      <div className="flex-item-left">
                        <h6>{text_brand_name}:</h6>
                      </div>
                      <div className="flex-item-right">{val.Brandname}</div>
                    </div>
                    <div className="flex-container">
                      <div className="flex-item-left">
                        <h6>{text_generic_name}:</h6>
                      </div>
                      <div className="flex-item-right">{val.Genericname}</div>
                    </div>
                    <div className="flex-container">
                      <div className="flex-item-left">
                        <h6>{text_dose}:</h6>
                      </div>
                      <div className="flex-item-right">{val.Dose}</div>
                    </div>
                    <div className="flex-container">
                      <div className="flex-item-left">
                        <h6>{text_strength}:</h6>
                      </div>
                      <div className="flex-item-right">{val.Strength}</div>
                    </div>
                    <div className="flex-container">
                      <div className="flex-item-left">
                        <h6>{text_expiry_date}:</h6>
                      </div>
                      <div className="flex-item-right">
                        <div className="sharetodataDatepicker">
                          <DatePicker
                            dropdownMode="select"
                            className=" mob-shareto-date"
                            onChange={(date) => setRefillExpiryDate(date)}
                            dateFormat="dd MMM, yyyy"
                            selected={RefillExpiryDate}
                            minDate={new Date()}
                            popperPlacement="top"
                            placeholderText={Text_select_expiry_date}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="flex-item-left">
                        <h6>{text_buy_date}:</h6>
                      </div>
                      <div className="flex-item-right">
                        <div className="sharetodataDatepicker">
                          <DatePicker
                            dropdownMode="select"
                            className=" mob-shareto-date"
                            onChange={(date) => setRefillBuyDate(date)}
                            dateFormat="dd MMM, yyyy"
                            selected={RefillBuyDate}
                            maxDate={new Date()}
                            popperPlacement="top"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <Divider />
            <div className="d-flex gap-3 justify-content-center mt-4">
              <button
                className="cancelbtn"
                onClick={() => [
                  setConfirmPopup(false),
                  setRefillDataId(""),
                  setRefillExpiryDate(),
                  setRefillBuyDate(new Date()),
                ]}
              >
                {text_No}
              </button>
              <button
                className="savebtn"
                onClick={handleRefilldata}
                disabled={saveDisable}
              >
                {text_yes}
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {pairMdicinePopup ? (
        <>
          <div className="Popup addmedicinePopup pairRefillPopup">
            <div className="text-end">
              <CgCloseO size={25} onClick={() => closePopup()} />
            </div>
            <h3 className="text-center">
              <>{text_confirmation_msg}</>
            </h3>
            <Divider />
            <div className="d-flex gap-3 justify-content-center mt-4">
              <button className="cancelbtn-pair" onClick={() => closePopup()}>
                {text_pair_later}
              </button>
              <button className="savebtn-pair" onClick={pairPopupData}>
                {text_pair_now}
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {alertPopup === true ? (
        <>
          <div
            className={
              pairMdicinePopup
                ? "Popup addmedicinePopup pairRefillPopup blurBg"
                : "Popup addmedicinePopup pairRefillPopup"
            }
          >
            <div className="text-end">
              <CgCloseO
                size={25}
                onClick={() => [
                  setAlertPopup(false),
                  setAlertDays("5"),
                  setAlertTime(setHours(setMinutes(new Date(), 0), 9)),
                  setSelectedTimeZone(FinalData[0]?.utc),
                  // setConfirmPopup(false),
                  setPairMedicinePopup(true),
                ]}
              />
            </div>
            <h3 className="text-center">
              <>{text_medicine_expiry}</>
            </h3>
            <Divider />
            <h5 className="text-center mt-3"></h5>
            <div className="d-flex gap-3 justify-content-left mt-2"></div>

            <div className="text-center mt-2">
              <h5>{text_alert_befor_expiry}</h5>
              <div className="d-flex justify-content-center">
                <input className=" input-number-in-days" value="In Days:" />
                <input
                  type="number"
                  onKeyDown={(evt) =>
                    evt.which !== 8 &&
                    evt.which !== 0 &&
                    (evt.which < 48 || evt.which > 57) &&
                    evt.preventDefault()
                  }
                  className="input-number-alert"
                  max={10}
                  min={0}
                  onChange={(e) => setAlertDays(e.target.value)}
                  value={alertDays}
                />
              </div>
            </div>
            <div className="d-flex gap-5 mx-4">
              <div className=" mt-2 mb-3 mx-3 " id="alert-time">
                <h5>{text_alert_time}</h5>
                <ReactDatePicker
                  className="frequencyInput "
                  onChange={(date, id) => setAlertTime(date)}
                  disableDayPicker
                  format="hh:mm a"
                  plugins={[<TimePicker hideSeconds />]}
                  value={new Date(AlertTime).getTime()}
                />
              </div>
              <div className="mt-1 mb-2 mx-4">
                <h5>{text_timezone} </h5>
                <select
                  className="timezone-addmedicine timezone-sty"
                  onChange={(e) => setSelectedTimeZone(e.target.value)}
                >
                  {FinalData?.map((val, i) => {
                    return (
                      <>
                        <option value={val?.utc}>{val?.label} </option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>

            <Divider />
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                className="cancelbtn"
                onClick={() => [
                  setAlertPopup(false),
                  setAlertDays("5"),
                  setAlertTime(setHours(setMinutes(new Date(), 0), 9)),
                  setSelectedTimeZone(FinalData[0]?.utc),
                  // setConfirmPopup(false),
                  setPairMedicinePopup(true),
                ]}
              >
                {btn_cancel}
              </button>
              <button className="savebtn" onClick={handleAlertSetting}>
                {btn_save}
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Mydevice;
